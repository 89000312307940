@import "../global";

.showcase {

    height: calc(var(--vph, 100vh) - 120px);

    .avatar {
        display: flex;
        height: 100%;
        width: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .Carousel,
    .Swiper {
        color: #494949;
        height: calc(var(--vph, 100vh) - 120px);

        .Show {
            height: calc(var(--vph, 100vh) - 130px);
        }

        .Banner {
            height: calc(var(--vph, 100vh) - 200px);
            position: relative;

            .Media {
                background-color: black;
                height: 100%;
                overflow: hidden;
                background-size: contain;

                position: relative;

                .MediaCaption {
                    text-overflow: ellipsis;

                    position: absolute;
                    bottom: 0;

                    padding: 15px;

                    background-color: black;
                    color: white;
                    opacity: 0.6;

                    width: 100%;
                    height: 10%;

                    font: {
                        size: $bigger-font-size;
                        weight: 200;
                    }

                    transition: 300ms;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }

                transition: 300ms;
                cursor: pointer;

                &:hover {
                    filter: brightness(115%);
                }
            }

            .BannerGrid {
                height: 100%;
                position: relative;
                flex-direction: column;
            }

            .ContentGrid {
                max-width: unset;
                flex-basis: unset;
                max-height: 60px;
                flex-direction: column;
            }

            .MediaGrid {
                max-width: unset;
                flex-basis: unset;
                flex-grow: 1;
                flex-direction: column;
            }

            .Content {
                color: white;
                background-color: $dark-red;
                height: 100%;
                padding: 10px;
                position: relative;

                cursor: pointer;

                transition: 300ms;

                .Actions {
                    padding: 0;
                    margin-left: auto;
                    margin-right: auto;
                    width: fit-content;

                    .Checkbox {
                        color: white;
                    }
                }

                &:hover,
                &:active {
                    background-color: $dark-red-active;

                    .ViewButton {
                        background-color: $almost-white;
                        color: $dark-red;
                    }
                }

                .Title {
                    font-size: $big-header-font-size;
                    font-weight: 500;
                    color: white;
                }

                .Caption {
                    margin-top: 10px;
                    font-size: $normal-font-size;
                    color: white;
                }

                .ViewButton {
                    margin-top: 40px;
                    color: white;

                    font-size: $header-font-size;
                    border: 3px solid white;
                    text-transform: capitalize;

                    transition: 200ms;
                }
            }
        }
    }

    .Swiper {
        .Banner {
            height: calc(var(--vph, 100vh) - 160px);
        }

        --swiper-navigation-color: #771818;
        --swiper-pagination-color: #771818;
    }

    .lonely {
        text-align: center;
    }

    .helpText {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%);
    }

    .scoreSheet {
        background-color: white;
        height: calc(var(--vph, 100vh) - 120px);
        width: 100%;
        color: black;
        text-align: left;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 54px 1fr 4em;
    }
}
