.imageCatalogView {
  text-align: left;

  .lonely {
    text-align: center;
  }

  .helpText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cardContent {
  padding: 8px;

  h6 {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.cardMedia {
  object-fit: contain !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.cardActions {
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  width: 200px;

  position: absolute;
  bottom: 4px;
  right: 0;
}

.cardButtons {
  @media (min-width: 710px) {
    margin-left: 211px;
  }
}

.loader {
  position: absolute;
  top: calc(50% - 42px);
  left: calc(50% - 42px);
}
