$desktopWidthBreak: 900px;

.lowerThird {
    @media (max-width: $desktopWidthBreak) {
        display: none;
    }

    height: 2em;
    max-height: 2em;
    width: 100%;
    max-width: calc(100vw - 17% - 25px);
    position: absolute;
    text-align: right;
    top: calc(90vh - 2em);
    font-size: 2em;
    font-weight: 900;
    color: white;
    padding: 0.4em;
    display: flex;
    flex-direction: row;
    z-index: 100000;
    background-color: white;
    color: black;
    border: 4px solid black;

    .lowerThirdContent {
        position: relative;
        display: flex;
        min-width: 100%;
        max-width: 100%;
        width: 100%;
    }
}

.slide {
    animation-timing-function: cubic-bezier(0.001, 0.005, 0.08, 0.001);
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-delay: 2s;
}

.in {
    animation-name: slide-in;
    left: 101%;
    animation-fill-mode: forwards;
}

.out {
    animation-name: slide-out;
    left: 15%;
    animation-fill-mode: forwards;
}

.content {
    padding: 0.4em;
}

.logo {
    max-height: calc(100% - 0.2em);
}

@keyframes slide-in {
    0% {
        left: 101%;
    }

    100% {
        left: 15%;
    }
}

@keyframes slide-out {
    0% {
        left: 15%;
    }

    100% {
        left: 101%;
    }
}
