$smallScreenWidthBreak: 1200px;

.interstitial {
    gap: 10px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;

    .standBy {
        font-size: 32px;
        animation: pulsate 3s ease-out;
        animation-iteration-count: infinite;
        opacity: 0;
    }
}

@keyframes pulsate {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
