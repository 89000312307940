.text {
  position: fixed;
  bottom: 0;
  top: auto;
  height: auto;
  left: 0;
  width: 100%;
}

.menuTitle {
  padding: 0 8px;
  display: block;
}

.buttons {
  right: 10px;
  position: absolute;
  background-color: white;
}

.actions {
  background-color: white;
  margin-right: 20px;
}

.searchBox {
  input {
    color: white;
  }
}

.info {
  text-align: left;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 160px);
  overflow: hidden;
}
