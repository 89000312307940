$footerHeight: 53px;
$headerHeight: 80px;

.scanner {
    text-align: center;
    min-height: var(--vph, 100vh);
    font-size: calc(10px + 2vmin);
    color: black;
    background-color: white;

    text-align: center;
    display: flex;

    height: calc(var(--vph, 100vh));
    flex-direction: column;
    width: 100%;
    overflow: hidden;

    .feedback {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        max-width: 600px;
        border: 4px solid;

        video {
            height: auto;
            width: 80%;
            max-width: 600px;
            height: auto;
        }
    }

    .widget {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 300px;
        height: 200px;
    }
}
