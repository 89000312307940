$footerHeight: 53px;
$headerHeight: 80px;

.scoreBox {
    text-align: center;
    min-height: var(--vph, 100vh);
    font-size: calc(10px + 2vmin);
    color: white;
    background-color: black;

    text-align: center;
    display: flex;

    height: calc(var(--vph, 100vh));
    flex-direction: column;
    width: 100%;
    overflow: hidden;


    .scoringBox {
        width: 100%;
        height: calc(var(--vph, 100vh) - 162px - 6em);
        align-items: center;
        justify-content: center;
    }

    .voteIconContainer {
        display: flex;
        align-items: center;
        padding: 20px;
    }

    .voteIcon {
        display: block;
        height: 100%;
        width: 100%;
        ;
    }

    .score {
        height: 8em;
        width: 8em;
        border: 2px solid white;
        margin: 0 1em;

        & h2,
        & div {
            font-size: 6em;
            margin-top: 10px;
        }

        &.noScore {
            color: slategray;

            & h2,
            & div {
                opacity: 0.2;
            }
        }

        &.abstain {
            color: slategray;
            border-color: darkslateblue;

            & h2,
            & div {
                opacity: 0.2;
            }
        }

        &.combined {
            border-color: rgb(33, 33, 33);
            color: rgb(33, 33, 33);

            &.final {
                border-color: red;
                color: white;
            }
        }
    }

    .actions {
        visibility: hidden;

        button {
            font-size: 1.2em;
            margin: 0.8em;
            border: 1px solid;
            padding: 0.8em;
        }

        &.final {
            visibility: visible;
        }
    }
}
