$footerHeight: 53px;
$headerHeight: 80px;

.console {
    text-align: center;
    min-height: var(--vph, 100vh);
    font-size: calc(10px + 2vmin);
    color: white;

    background-color: black;

    text-align: center;
    display: flex;

    height: calc(var(--vph, 100vh));
    flex-direction: column;
    width: 100%;
    overflow: hidden;

    .imageContainer {
        position: relative;
        box-shadow: unset;

        .image {
            width: 100%;
            height: calc(var(--vph, 100vh) - 340px);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }

        .data {
            width: auto;
            padding: 20px;
            position: relative;
            bottom: 50px;
            background-color: rgba(50, 20, 200, .8);
            color: white;
        }
    }

    .imageGrid {
        overflow-y: scroll;
        height: calc(var(--vph, 100vh) - 126px);
    }

    .leader {
        width: 100%;
        font-size: 1.1em;

        .score {
            float: right;
            font-weight: 700;
        }

        .place {
            margin-right: 1em;
            font-weight: 500;
        }

    }

    .cardContent {
        margin-top: 8px;
        text-align: center;
    }

    .consoleGrid {
        display: grid;
        height: 100%;
        max-height: calc(var(--vph, 100vh) - 112px);
        padding: 0 25px;
        width: 100%;
        max-width: calc(100vw - 50px);
        grid-template-areas:
            "start    preview"
            "round    preview"
            "actions  preview";
        grid-template-columns: 500px 1fr;
        grid-template-rows: 200px auto 1fr;
        ;
        grid-gap: 20px;

        .startButton {
            grid-area: start;
            align-items: center;

            &>button {
                display: block;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .roundInformation {
            grid-area: round;
        }

        .imageViewArea {
            grid-area: preview;
        }

        .imageActions {
            grid-area: actions;
            overflow-y: scroll;
        }
    }
}
