@import "../global";

.leaderboard {

    .avatar {
        display: flex;
        height: 100%;
        width: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }


    .scoreSheet {
        background-color: white;
        height: calc(var(--vph, 100vh) - 120px);
        width: 100%;
        color: black;
        text-align: left;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 54px 1fr;
    }

    .scoreHeader {
        display: flex;
        flex-direction: column;
        padding: 10px;

        &:hover {
            background-color: lightpink;
        }

        .label {
            margin: 0;
            padding: 0;
            text-align: right;
            line-height: 1em;
        }
    }

    .lonely {
        text-align: center;
    }

    .helpText {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%);
    }
}
