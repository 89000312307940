$footerHeight: 53px;
$headerHeight: 80px;
$desktopWidthBreak: 1200px;
$smallScreenHeightBreak: 900px;
$tabletScreenWidthBreak: 730px;
$slideShowSlideHeight: calc((var(--vph, 100vh)) - 100px);

@mixin basic-black-scrollbar {
    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.8);
    }

    &::-webkit-scrollbar-thumb {
        border: 3px solid black;
        background-color: rgba(128, 128, 128, 0.5);
    }

    /* mozilla only */
    scrollbar-color: rgba(255, 255, 255, 0.2) rgba(0, 0, 0, 0.8);
    scrollbar-width: thin;
}

.scorePad {
    text-align: center;
    min-height: var(--vph, 100vh);
    font-size: calc(10px + 2vmin);
    color: white;
    background-color: black;

    text-align: center;
    display: flex;

    height: calc(var(--vph, 100vh));
    flex-direction: column;
    width: 100%;
    overflow: hidden;

    @media (max-height: $smallScreenHeightBreak) {
        font-size: 10px;
    }

    h1 {
        font-size: 6em;
    }

    header {
        @media (max-height: $smallScreenHeightBreak) {
            display: none;
        }
    }

    .score {
        color: white;
    }

    .actionButton {
        height: 3.8em;
        font-size: 24px;
        margin: 5px;

    }

    .slider {
        @media (max-height: $smallScreenHeightBreak) {
            display: none;
        }

        color: white;
        max-width: 80%;
        margin: 0 auto;
    }

    .voteButton:disabled,
    .scoreButton:disabled {
        background-color: gray;
        color: white;
    }

    .voteButton,
    .scoreButton {
        @media (max-width: $desktopWidthBreak) {
            max-width: 50%;
        }

        &:hover {
            background-color: darkblue;
        }

        background-color: dodgerblue;
    }

    .voteButton.voted,
    .scoreButton.scored {
        background-color: gray;
    }

    .abstainButton {
        @media (max-width: $desktopWidthBreak) {
            max-width: 40%;
        }

        &:hover {
            background-color: darkgoldenrod;
        }

        background-color: goldenrod;
    }

    .abstainButton.abstained {
        pointer-events: none;
        background-color: red;
    }

    .clubName {
        @media (max-width: 1200px) {
            display: none;
        }
        font-style: italic;
        color: #b570ff;
    }

    .Swiper {
        color: black;
        height: $slideShowSlideHeight;
        width: 100vw;

        .Show {
            height: $slideShowSlideHeight;
            width: 100vw;

            .reviewSlide {
                position: relative;
                height: 100%;
                width: 100%;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;

                .identificationBug {
                    position: relative;
                    background-color: #b570ff;
                    border: 4px solid white;
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    color: black;
                    border-radius: 0 0 25% 25%;
                    font-size: 0.3em;
                    margin-right: 2em;
                    margin-left: 2em;
                    max-width: 12em;
                    min-width: 12em;
                    top: 0;
                    right: 0;
        
                    .pretext {
                        font-size: 0.3em;
                        font-size: 0.4em;
                        background-color: rgb(233, 202, 200);
                        padding: 0.8em;
        
                        display: grid;
                        grid-template-columns: 24px 1fr;
                        grid-gap: 8px;
        
                        .logo {
                            max-width: 24px;
                            max-height: 24px;
                            margin-top: auto;
                            margin-bottom: auto;
                        }
                    }
        
                    .indexNumber {
                        font-size: 3em;
                        font-weight: 900;
                        color: white;
                    }
                }
            }

            .slide {
                height: $slideShowSlideHeight;

                .winnerImage {
                    height:  $slideShowSlideHeight;

                    .image {
                        object-fit: contain;
                        height: $slideShowSlideHeight;
                        width: 100%;
                    }
                }

                .imageInfo {
                    z-index: 1111;
                    bottom: 6px;
                    width: 100%;
                    height: 40px;
                    background: #808080;
                }
            }
        }
    }

    .bumper {
        display: grid;
        grid-template-columns: 1fr 200px;
        width: 100%;

        .info {
            line-height: 1;
            text-align: left;
            position: relative;
            top: -12px;
        }

        h3 {
            display: block;
            max-height: 2.4em;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            line-height: 1.2;
        }

        .scoreBox {
            background-color: #b570ff;
            border: 4px solid black;
            position: relative;
            display: flex;
            flex-direction: column;
            text-align: center;
            color: black;
            border-radius: 0 0 25% 25%;
            font-size: 0.3em;
            margin-right: 2em;
            margin-left: 2em;
            max-width: 8em;
            min-width: 8em;
            top: -3em;
            right: 1em;

            .pretext {
                font-size: 0.3em;
                font-size: 0.4em;
                background-color: rgb(233, 202, 200);
                padding: 0.8em;
            }

            .scoreNumber {
                font-size: 3em;
                font-weight: 900;
                color: white;
            }

            .scoreText {
                font-size: 0.8em;
            }
        }
    }

    .soloImage {
        height: calc(var(--vph, 100vh) - 100px);
        width: 100vw;

        .image {
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }

    .scoreInput {
        color: white;
        max-width: 4em;

        input {
            color: white;
            font-size: 48px;
        }

        label {
            color: white;
        }

        fieldset {
            border-color: white;
        }
    }

    .numberPad {
        min-height: 50%;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-evenly;

        .touchTone {
            padding: 8px;
            border: 2px solid;
            display: inline-block;
            flex-grow: 24;
            flex-shrink: 24;
            flex-basis: auto;
            margin: 8px;
            min-width: 40%;

            &.selected {
                background-color: white;
                color: black;
                border-color: dodgerblue;
            }
        }
    }

    .ballot {
        min-height: 50%;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-evenly;

        .touchTone {
            padding: 8px;
            border: 2px solid;
            display: inline-block;
            flex-grow: 24;
            flex-shrink: 24;
            flex-basis: auto;
            margin: 8px;
            min-width: 40%;

            &.selected {
                background-color: white;
                color: black;
                border-color: dodgerblue;
            }
        }
    }

    .slider {
        margin: 10px;
        color: white;
    }

    .buttonText {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .scoringGrid {
        display: grid;
        overflow-y: scroll;
        grid-template-columns: 1fr 33%;
        height: calc(var(--vph, 100vh) - 100px);
        grid-gap: 20px;

        @include basic-black-scrollbar();

        @media (max-width: $tabletScreenWidthBreak) {
            grid-template-columns: 1fr;

            .imagePreview {
                display: none;
            }
        }

        .imagePreview {
            .image {
                height: calc(100% - 12px);
                width: 100%;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }

        .imageActions {
            .content {
                padding: 20px;
                position: relative;
                display: block;
                top: 50%;
                transform: translateY(-50%);
            }
        }

    }

}
